.search-breadcrumb {
    margin: rem-calc(16 0);
    @include breakpoint (medium down) {
        margin-bottom: rem-calc(8);
    }

    &__item {
        line-height: rem-calc(15);

        a {
            font-size: rem-calc(10);
            line-height: rem-calc(15);
            font-weight: 400;
        }

        &:last-of-type a {
            pointer-events: none;
            font-weight: 500;
        }
    }

    &__separator {
        font-size: rem-calc(10);
        line-height: rem-calc(9);
        display: inline-block;
        margin: rem-calc(0 4);
    }
}