.special-refinements {
  display: flex;
  justify-content: center;
  overflow: hidden;
  padding: rem-calc(0 24);

  &-container {
    position: relative;

    // Custom slider controls
    .slick-arrow {
      position: absolute;
      top: 38%;
      transform: translateY(-50%);
      cursor: pointer;

      svg {
        width: rem-calc(16);
        height: rem-calc(16);
        padding: rem-calc(12);
        box-sizing: content-box;
      }

      &.custom-slick-prev,
      &.custom-slick-next {
        display: none;
        z-index: 1;

        // Only show custom slick arrows when the carousel is active
        .slick-slider.slick-initialized & {
          display: block;
        }

        &.slick-disabled {
          opacity: .3;
        }
      }

      &:focus {
        outline: 0;
      }

      @include breakpoint (medium down) {
        display: none;
      }
    }

    &--tezenis {
      position: relative;
      justify-content: center;
      width: 70%;
      margin: 0 auto;
      @include breakpoint (medium down) {
        width: 100%;
      }
      .slick-arrow.custom-slick-prev {
        left: 0;
      }
      .slick-arrow.custom-slick-next {
        right: 0;
      }
    }
  }

  &-title {
    @include breakpoint (medium down) {
      margin-top: rem-calc(16);
    }

    @include breakpoint (small down) {
      margin-top: rem-calc(13);
    }

    &--tezenis {
      margin-top: rem-calc(15);
      margin-bottom: 0;
      color: inherit;
      font-size: rem-calc(28);
      font-weight: 600;
      letter-spacing: 0.4px;
      @include breakpoint (medium down) {
        font-size: rem-calc(26);
      }
      @include breakpoint (small down) {
        text-align: center;
        font-size: rem-calc(22);
        margin-bottom: rem-calc(5);
      }
    }
  }

  &--wrapper {
    position: relative;

    @include breakpoint (medium down) {
      margin: 0 rem-calc(-51);
    }

    @include breakpoint (small down) {
      margin: 0 rem-calc(-12);
    }
  }

  &--tezenis {
    display: none;
    width: 100%;
    text-align: center;

    @include breakpoint (small down) {
      padding: 0;
    }

    // MEC-9909 - improve loading speed of hidden category filters
    &.slick-initialized {
      display: block;
    }
  }
}

.special-refinement {
  text-align: center;
  border: 0;
  @include breakpoint (medium down) {
    &:last-child {
      margin-right: rem-calc(16);
    }
  }

  &.is-selected {
    .special-refinement__value {
     font-weight: 600;
    }

    .special-refinement__selected {
      display: flex;
      justify-content: center;
    }

    .special-refinement__selection-dot {
      width: rem-calc(4);
      height: rem-calc(4);
      bottom: rem-calc(-8);
      background: $black;
      border-radius: 50%;
      margin-top: rem-calc(5);
    }
  }

  &__value {
    font-size: rem-calc(12);
    line-height: rem-calc(18);
    font-weight: 500;
    margin: 0;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-all;
    max-width: calc(6.295754026354319vw - 16px);
    @include breakpoint (medium down) {
      max-width: calc(22.93333333333333vw - 15px);
    }
  }

  &__image {
    border-radius: rem-calc(8);
    margin-bottom: rem-calc(8);
    width: 6.295754026354319vw;

    @include breakpoint (medium down) {
      width: 22.93333333333333vw;
    }
  }

  @include breakpoint (medium down) {
    &.placeholder-refinement {
      visibility: hidden;
      display: block;
    }
  }
}

.category-refinements {
  @include breakpoint (medium down) {
    width: calc(100% + 16px);
    overflow-x: auto;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &__list {
    margin-bottom: rem-calc(16);
    gap: rem-calc(16);
    @include breakpoint (large up) {
      justify-content: center;
    }
    @include breakpoint (medium down) {
      margin: rem-calc(0 0 28 0);
      width: unset;
      gap: rem-calc(8);
    }
  }
}

.search-results {
  &__refinements-scroll,
  &__refinements-thirdLevel {
    &.fixed {
      z-index: 2;
      background: white;
      position: fixed;
      width: 100%;
      left: 0;
      top: 0;
      transform: translateY(-100%);
      padding-top: rem-calc(16);
    }
    &.fixed,
    &.cc-thirdLevelCatRefinements {
      .category-refinements {
        &.gender-filters {
          display: none;
        }
        &__list {
          justify-content: flex-start;
          gap: rem-calc(8);

          .special-refinement {
            border-radius: rem-calc(20);
            border: rem-calc(1) solid $light-gray;
            padding: rem-calc(8 16);

            &__value {
              margin-top: 0;
              max-width: unset;
            }

            &__image,
            &__selected {
              display: none;
            }

            &:hover {
              transition: all 0.2s ease;
              border-color: transparent;
              background-color: $water-green-light;
            }

            &.is-selected {
              border-color: $water-green;
              background-color: $water-green-light;
            }
          }
        }
      }
    }
  }
  .cc-thirdLevelCatRefinements {
    .category-refinements__list {
      margin-bottom: rem-calc(34);
      @include breakpoint (large up) {
        margin-bottom: rem-calc(20);
      }
    }
  }
}
