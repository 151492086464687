
#filtersModal {
  border-left: 1px solid $light-gray;
  border-radius: 0;
  box-shadow: none;
  max-width: none !important;
  min-height: 100% !important;
  position: absolute;
  right: 0;
  width: rem-calc(459);
  @include breakpoint (small down) {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: fixed;
  }
  @include breakpoint (medium up) {
    padding: rem-calc(0 42 0 24);
  }
  @include breakpoint(medium down) {
      min-width: 100vw !important;
      width: 100vw;
  }
  .filters-title {
    font-size: rem-calc(14);
    font-weight: 500;
    line-height: rem-calc(21);
    letter-spacing: rem-calc(0.2);

    @include breakpoint (small down) {
      font-weight: 600;
    }
  }
  .filters {
    .filter-body {
      position: relative;
      padding: rem-calc(0 3);

      .values.content {
        list-style-type: none;
        margin: 0;
        margin-top: rem-calc(25);

        &.second-row {
          margin-top: rem-calc(0);

          .value {
            &.color {
              margin-top: rem-calc(8);

              &:last-child {
                margin-right: rem-calc(0);
              }
            }
          }
        }

        .value {
          display: inline-block;

          &.color {
            height: rem-calc(30);
            margin-right: rem-calc(16);
            width: rem-calc(30);

            &:last-child {
              margin-right: rem-calc(0);
            }

            .boolean-field {
              padding-right: 0;
            }
          }

          .boolean-field {
            padding-right: rem-calc(6);
            margin-bottom: rem-calc(0);
          }

          input:checked + .primary-tag {
            background-color: $water-green-light;
            border: 1px solid $water-green;
            color: $medium-gray;
          }

          .cc-filterChecked {
            .primary-tag {
              background-color: $water-green-light;
              border: 1px solid $water-green;
              color: $medium-gray;
            }
          }
          
        }
      }
    }

    .filter-accordion {
      position: relative;
      .filter-icon {
        height: rem-calc(20);
        width: rem-calc(20);
      }
      .filter-name {
        font-size: rem-calc(12);
        font-weight: 400;
        line-height: rem-calc(18);
        letter-spacing: rem-calc(0.2);
      }
      .triangle {
        display: inline-block;
        height: rem-calc(20);
        margin-left: rem-calc(8);
        position: absolute;
        right: 0;
        vertical-align: middle;
        width: rem-calc(20);
        &.is-open {
            transform: rotate(180deg);
        }
      }
    }
    .sort-orders {
      border-bottom: 1px solid $light-gray;
      margin-bottom: rem-calc(40);
      padding-bottom: rem-calc(24);

      span {
        font-size: rem-calc(12);
        font-weight: 400;
        line-height: rem-calc(18);
        letter-spacing: rem-calc(0.2);
      }

      .filter-body {
        margin-top: rem-calc(25);
        position: relative;

        .sort-order-item {
          margin-bottom: rem-calc(4);

          &:last-of-type {
            margin-bottom: 0;
          }
          .boolean-field {
            position: unset;
          }
          input[type=radio] {
            left: unset;
            position: absolute;
            right: 0;
          }
        }

        .radio-input {
          border-radius: rem-calc(12);
          height: rem-calc(19);
          margin-right: 0;
          padding-left: 0;
          position: absolute;
          right: 0;
          width: rem-calc(19);
        }
      }
    }

    .refinements {
      margin-bottom: rem-calc(24);
      .refinement {
        margin-bottom: rem-calc(34);
        @include breakpoint (medium up) {
          &#availableForInStorePickup {
            margin-bottom: rem-calc(30);
          }
        }
      }
    }
  }

  .modal-header {
    padding: rem-calc(24 0 24 0);
    position: relative;

    .modal-close-filters {
      background-image: url("../../images/icon-close.svg");
      background-repeat: no-repeat;
      background-size: rem-calc(24 24);
      cursor: pointer;
      display: block;
      position: absolute;
      right: 0;
      top: rem-calc(24);
      height: rem-calc(24);
      width: rem-calc(24);
    }
  }

  .modal-body {
    height: calc(100vh - 188px);
    overflow-y: scroll;
    margin-top: rem-calc(17);
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    @include breakpoint (small down) {
      flex: 1;
      height: 100%;
      margin-top: rem-calc(9);
    }
  }

  .modal-footer {
    border-top: 1px solid $light-gray;
    bottom: 0;
    left: 0;
    padding-top: rem-calc(24);
    position: absolute;
    width: 100%;

    @include breakpoint (small down) {
      position: unset;
    }

    .modal-footer__actions {
      align-items: center;
      display: flex;
      justify-content: space-between;

      .reset {
        border-bottom: 1px solid $black;
        bottom: rem-calc(12);
        color: $black;
        font-size: rem-calc(12);
        font-weight: 400;
        line-height: rem-calc(18);
        left: rem-calc(40);
        letter-spacing: rem-calc(0.2);
        position: relative;

        @include breakpoint (small down) {
          left: rem-calc(0);
        }

        &.disabled {
          color: $medium-gray;
          text-decoration: none;
        }

        &:hover {
          border: none;
        }
      }
    }

    .button-primary-black {
      margin-bottom: rem-calc(24);
      margin-right: rem-calc(42);

      @include breakpoint (small down) {
        margin-right: rem-calc(0);
      }
    }
  }
}
.filters-and-order {
  margin-bottom: rem-calc(16);
  line-height: rem-calc(15);

  &__products {
    font-size: rem-calc(10);
    line-height: rem-calc(15);
  }

  &__link {
    font-weight: 500;
    font-size: rem-calc(12);
    line-height: rem-calc(18);
    letter-spacing: rem-calc(0.2);
    display: inline-block;

    &.reset {
      font-weight: 400;
      margin-right: rem-calc(16);
    }
  }

  &__icon {
    width: rem-calc(8);
    margin: rem-calc(0 0 0 8);
  }
}
