// Override Foundation Pagination
.pagination {
    display: inline-block;
    margin-left: rem-calc(12);
    float: right;

    a,
    button {
        padding: 0;
        margin: rem-calc(0 4);
        font-size: rem-calc(15);
        &:hover {
            background: transparent;
        }
        &.pagination{
            &__button {

                display: none;
                &--nav-left, &--nav-right {
                    display: inline-block;
                    font-size: rem-calc(12);
                    margin: rem-calc(0 8);

                    &.hide-nav {
                        display: none;
                    }
                }
                &--show {
                    width: rem-calc(23);
                    height: rem-calc(23);
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    font-size: rem-calc(12);
                }

                &--active{
                    height: rem-calc(23);
                    width: rem-calc(23);
                    border: 1px solid $black;
                    text-align: center;
                    font-weight: 600;
                }
            }
        }
    }

    .current {
        background: $body-background;
        border: 1px solid $black;
        padding: rem-calc(0 7);
        color: $black;
        font-weight: $global-weight-bold;
    }

    .disabled {
        display: none !important;
    }

    &-previous a,
    &-previous.disabled {
        padding: 0;

        &::before {
            content: none;
        }
    }

    &-next a,
    &-next.disabled {
        padding: 0;

        &::after {
            content: none;
        }
    }

    &-previous {
        svg {
            transform: rotate(90deg);
            margin: 0;
        }
    }

    &-next {
        svg {
            transform: rotate(270deg);
            margin: 0;
        }
    }

    &--tezenis {
        border-top: 1px solid #D2D6D8;
        padding-top: rem-calc(15);
        @include breakpoint(medium up){
            width: calc(100% - 0.742vw);
        }
        @media (max-width: 768px) {
            display: block;
            text-align: center;
            padding-top: rem-calc(19);
        }
        .total-products--tezenis {
            flex: 1;
            color:#949494;
            text-transform: lowercase;
            align-self: flex-end;
            @media (max-width: 768px) {
                display: inline-block;
                margin-bottom: rem-calc(15);
            }
        }
        .pagination-wrapper {
            flex: 1;
            text-align: right;
            @media (max-width: 768px) {
                text-align: center;
            }
            ul.pagination {
                padding: rem-calc(0 10);
                margin: 0;
                float: none;
                border-radius: 25px;
                border: 1px solid #e2e2e2;
                a.pagination__button {
                    font-weight: 400;
                    font-size: rem-calc(15);
                    color:#949494;
                    width: auto;
                    height: auto;
                    padding: rem-calc(4 4 3);
                    &--active {
                        color: $black;
                        border: none;
                        cursor: initial;
                    }
                }
            }
        }
    }
}


.footer-pagination {
    margin-bottom: rem-calc(56);
    @include breakpoint (medium down) {
        margin-top: rem-calc(16);
    }

    &__label {
        font-size: rem-calc(14);
        line-height: rem-calc(21);
        margin-bottom: rem-calc(16);
    }

    &__link {
        font-size: rem-calc(12);
        line-height: rem-calc(12);
        font-weight: 500;
        color: $black;
        background: $white;
        border: 1px solid $light-gray;
        padding: rem-calc(8 16);
        border-radius: rem-calc(20);

        &:hover {
            background-color: $light-gray;
        }

        &.active {
            background-color: $light-gray;
            border-color: $medium-light-gray;
        }
    }

    &__link,
    &__dots {
        margin-right: rem-calc(8);
    }

    &__arrow {
        &-icon {
            margin: rem-calc(0 10);
            width: rem-calc(12);

            &--next {
                margin-left: rem-calc(2);
            }
        }
    }
}
