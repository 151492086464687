$include-fonts: false;

@import "../setup/settings";
@import "../mixins/helpers";
@import "../plugins/slick";
@import "plp/quickview";
// @import "plp/filters"; TODO - commented out for TEZ Restyle | Filters
// @import "plp/refinements"; TODO - commented out for TEZ Restyle | Filters
@import "plp/specialRefinements";
// @import "plp/sortOptions"; TODO - commented out for TEZ Restyle | Filters
@import "plp/filtersModal";
@import "plp/pagination";
@import "plp/breadcrumbs";
@import "plp/tile";

$border-bottom-gray: #F5F5F5;
$margin-left-right-total: 4.453125vw;
$margin-left-right-positive: $margin-left-right-total / 2;
$margin-left-right-negative: ($margin-left-right-total / 2) * -1;
// Fix to avoid autoscroll to bottom in search results page
// when the user clicks "load more" in chrome
body {
    overflow-anchor: none;
}

.swatch-arrow {
    padding-left: rem-calc(4);
    padding-right: rem-calc(5);

    &.disabled {
        opacity: 0.2;
    }
}

.product-tile-cell:not(.product-tile-cell--big-one){
    .price-container--tezenis {
        @include breakpoint (small down) {
            padding-bottom: rem-calc(20);
        }
    }
}
.product-tile-cell--tezenis {
    .price-container--tezenis {
        @include breakpoint(large up){
            padding-bottom: rem-calc(40) !important;
            .big-product-template &{
                @media (min-width: 1024px) and (max-width: 1600px){
                    padding-bottom: rem-calc(27) !important;
                }
            }
        }
    }
}
.no-result-title {
    font-size: rem-calc(24);
    letter-spacing: rem-calc(0.7);
    font-weight: 700;
    @include breakpoint (small down) {
        font-size: rem-calc(22);
    }
}
.no-result-subtitle {
    font-size: rem-calc(16);
    @include breakpoint (small down) {
        font-size: rem-calc(14);
    }
}
.empty-category-container {
    .slick-list {
        min-width: 0;
        min-height: 0;

    }
    .slick-prev::before {
        display: none;
    }
    .slick-next::before {
        display: none;
    }
    .slick-track {
        min-width: 0;
        min-height: 0;
    }
    .slick-slide {
        .product {
            padding-left: rem-calc(20);
            padding-right: rem-calc(20);
            @include breakpoint (medium down) {
                padding-left: rem-calc(7.5);
                padding-right: rem-calc(7.5);
            }
        }
        &:first-child {
            .product {
                padding-left: 0;
            }
        }
        &:last-child {
            .product {
                padding-right: 0;
            }
        }
        &.product-tile-cell--tezenis {
            .price-container--tezenis {
                padding-bottom: 0!important;
            }
        }
    }
}
.grid-x.grid-margin-x.grid-header.grid-header--tezenis {
    display: block;
}

.no-products-result-section {
    padding-top: rem-calc(87);
    padding-bottom: rem-calc(87);
    @include breakpoint (small down) {
        padding-top: rem-calc(47);
        padding-bottom: rem-calc(47);
    }
}
.comingSoonBox {
    i {
        position: relative;
        @include breakpoint (small down) {
            height: rem-calc(45);
        }
        &:after {
            @include breakpoint (small down) {
                transform: translate(-50%, -50%) scale(0.8);
            }
        }
    }
    p {
        font-weight: 400;
        font-size: rem-calc(18);
        letter-spacing: 0.03em;
        line-height: rem-calc(24);
        color: $black;
        margin-top: 0;
        margin-bottom: 0;
        @include breakpoint (small down) {
            font-size: rem-calc(14);
            line-height: rem-calc(18);
        }
    }
    .button.add-to-wish-list-coming-soon,
    .button.find-in-store-tile {
        background-color: transparent !important;
        padding: 0;
        border: none;
        margin-bottom: 0;
        margin-top: rem-calc(20);
        outline: none;
        @include breakpoint (small down) {
            margin-top: rem-calc(15);
        }
        > span {
            color: $black;
            font-size: rem-calc(12);
            font-weight: 400;
            line-height: rem-calc(16);
            letter-spacing: 0.03em;
            border-bottom: 2px solid $black;
            display: inline-block;
            padding-bottom: rem-calc(4);
            @include breakpoint (small down) {
                font-size: rem-calc(11);
                line-height: rem-calc(15);
            }
        }
    }
}
#product-search-results {
    .fix-margin-4{
        @include breakpoint (small down) {
            margin: rem-calc(0 4);
        }
    }
    .grid-margin-x.product-grid {
        &.big-product-template{
            @include breakpoint (medium up) {
                margin-left: $margin-left-right-negative;
                margin-right: $margin-left-right-negative;
                .large-6.big--tezenis.product-big-tiles {
                    width: calc(50% - #{$margin-left-right-total});
                    margin-left: $margin-left-right-positive;
                    margin-right: $margin-left-right-positive;
                    .grid-margin-x {
                        margin-left: $margin-left-right-negative;
                        margin-right: $margin-left-right-negative;
                        .small-6.product-tile-cell {
                            width: calc(50% - #{$margin-left-right-total});
                            margin-left: $margin-left-right-positive;
                            margin-right: $margin-left-right-positive;
                        }
                    }
                }
                .special-banner-container,
                .pagination-container{
                    margin-left: 2.22656vw;
                    margin-right: 2.22656vw;
                    width: calc(100% - 4.45313vw);
                    .special-listing-bottom-banner,
                    .pagination--tezenis{
                        width: 100% !important;
                    }
                }
            }
            .image-container {
                height: 100%;
            }
            span.js-pdp-link  {
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
            .tile-image {
                height: 100%;
                object-fit: cover;
            }
            .small-12.product-tile-cell--big-one {
                height: 100%;
            }
            .m-video{
                width: 100%;
                height: 100%;
                object-fit: cover;
                @include breakpoint (medium down){
                    width: 100%;
                }
            }
            video + .plp-message-custom {
                position: relative;
                bottom: 5px;
            }
            .price-container--tezenis {
                display: grid;
                .price--tezenis {
                    display: inherit;
                    .onsale {
                        display: inherit;
                    }
                    @include breakpoint (tablet down){
                        .sales .value {
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }
                    &.cc-hidePercentage {
                        display: none;
                    }
                }
            }
        }
        &.listing-horizontal-template{
            @include breakpoint(medium up){
                .special-banner-container,
                .pagination-container{
                    margin-left: 1.4843vw;
                    margin-right: 1.4843vw;
                    width: calc(100% - 2.9687vw);
                    .special-listing-bottom-banner,
                    .pagination--tezenis{
                        width: 100% !important;
                    }
                }
            }
            @include breakpoint (small down) {
                .special-banner-container{
                    margin-top: 0;
                }
                .special-listing-bottom-banner,
                .pagination--tezenis{
                    width: 100% !important;
                    margin-left: 0;
                    margin-right: 0;
                }
            }
            .product-tile-cell:not(.product-tile-cell--big-one){
                .price-container--tezenis {
                    @include breakpoint (small down) {
                        padding-bottom: rem-calc(35);
                    }
                }
            }
        }
        .big--tezenis{
            @include breakpoint(large up){
                margin-bottom: 0;
            }
        }
        .product-tile-cell--big-one{
            @include breakpoint(small down){
                margin-bottom: rem-calc(15);
            }
        }
        .medium-option-horizontal-image{
            @include breakpoint(small down){
                margin-bottom: rem-calc(30);
            }
            .image-container{
                margin: 0 auto;
                width: auto;
                height: 28.6667vw;
                @include breakpoint(small down){
                    height: 66.4vw;
                }
                img{
                    height: 100%;
                    width: auto;
                }
            }
            .tile-body,
            .price-container{
                width: 19.1526vw;
                margin: 0 auto;
                @include breakpoint(small down){
                    width: 44.2vw;
                }
            }
        }
        @include breakpoint(small down){
            .fix-margin-grid{
                margin-left: rem-calc(-5);
                margin-right: rem-calc(-5);
                &-big-image{
                    margin-left: rem-calc(-9);
                    margin-right: rem-calc(-9);
                }
            }
            .fix-margin-cards{
                margin-bottom: 0;
                &:not(:first-of-type){
                    margin-top: 0;
                }
            }
        }
    }
}

.product-tile.big {
    display: flex;
    flex-flow: row nowrap;

    & .plp-swatch {
        float: initial;
    }

    & .image-container {
        flex: 0 0 auto;
        width: 50%;
        display: inline-block;
    }

    & .tile-body {
        flex: 0 0 auto;
        width: 50%;
        vertical-align: top;
        padding-left: rem-calc(20);
        display: flex;
        flex-flow: column nowrap;

        & > div, & > span {
            flex: 1 1 auto;
        }
    }

    & .shortdescription {
        font-weight: 300;
        font-size: rem-calc(10);
    }
}

.swatch {
    font-size: 0;
    & input[type="checkbox"]:checked + .checkbox-label::after {
        width: rem-calc(30);
        height: rem-calc(30);
        display: block;
        content: ' ';
        border: rem-calc(1) solid $black;
        position: absolute;
        top: rem-calc(-3);
        left: rem-calc(-3);
        border-radius: 50%;
    }

    & .bucket-swatch {
        height: rem-calc(24);
        width: rem-calc(24);
        display: inline-block;
        border-radius: 50%;
        vertical-align: middle;
        background-image: url('../images/bucketsprites.jpg');

        &.WHITE {
            border: rem-calc(1) solid $dark-gray;
            background-position: -44px -44px;
        }

        &.RED {
            background-position: -176px -132px;
        }
        &.ORANGE {
            background-position: -44px 0;
        }
        &.WHITE__AVORIO_ {
            background-position: -132px -88px;
        }
        &.LIGHT_BLUE {
            background-position: 0 -44px;
        }
        &.BLUE {
            background-position: -88px 0;
        }
        &.BORDEAUX {
            background-position: -88px -44px;
        }
        &.FLORAL {
            background-position: 0 -88px;
        }
        &.YELLOW {
            background-position: -44px -88px;
        }
        &.GREY {
            background-position: -88px -88px;
        }
        &.PALE_GREY {
            background-position: -132px 0;
        }
        &.DARK_GREY {
            background-position: -132px -44px;
        }
        &.BURGUNDY {
            background-position: -176px -132px;
        }
        &.METALLIC {
            background-position: 0 -132px;
        }
        &.MULTICOLOR {
            background-position: -44px -132px;
        }
        &.NUDE {
            background-position: -88px -132px;
        }
        &.BLACK {
            background-position: -132px -132px;
        }
        &.STRIPES {
            background-position: -132px -176px;
        }
        &.PINK {
            background-position: -176px -44px;
        }
        &.LIGHT_PINK {
            background-position: -176px -44px;
        }
        &.PALE_PINK {
            background-position: -176px -44px;
        }
        &.PRINT {
            background-position: 0 -176px;
        }
        &.GREEN {
            background-position: -44px -176px;
        }
        &.VIOLET {
            background-position: -88px -176px;
        }
        &.ANIMALIER {
            background-position: -176px 0;
        }
    }
}

.bonus-product {
    & .swatch-circle {
        margin-right: rem-calc(10);
        margin-bottom: rem-calc(10);
    }
}

.seo-container {
    padding: rem-calc(60) 0;

    @include breakpoint (medium down) {
        padding: rem-calc(30) rem-calc(0);
    }

    .ca-expandable {
        overflow: hidden;
        transition: max-height 1s ease;
    }
    
    .ca-expanded {
        transition: max-height 1s ease;
    }
    
    .ca-toggle-button-container {
        display: none;
        font-family: $body-font-family;
        font-weight: 400;
        font-size: rem-calc(12);
        line-height: rem-calc(18);
        letter-spacing: rem-calc(0.2);
        text-decoration: underline;
        margin-top: rem-calc(10);
        cursor: pointer;
    
        .ca-toggle-button-collapse {
            display: none;
        }
    }

    .grid-x.grid-margin-x {
        gap: rem-calc(16);

        .cell {
            margin: 0;
        }
    }

    .category-title {
        font-size: rem-calc(18);
        font-weight: $global-weight-dark;
        font-family: $body-font-family;
        line-height: rem-calc(27);
    }

    .category-description {
        display: inline-block;
        font-size: rem-calc(14);
        font-weight: $global-weight-normal;
        line-height: normal;
        letter-spacing: rem-calc(0.2);
    }

    .category-subtitle {
        font-size: rem-calc(14);
        font-weight: $global-weight-bold;
        line-height: normal;
        letter-spacing: rem-calc(0.2);
        margin-top: rem-calc(20);
        margin-bottom: rem-calc(8);
    }

    .category-text {
        font-size: rem-calc(12);
        font-weight: $global-weight-normal;
        line-height: normal;
        letter-spacing: rem-calc(0.2);
        margin-bottom: rem-calc(8);

        @include breakpoint (medium up) {
            margin-bottom: rem-calc(8);
        }

        a {
            text-decoration: underline;
            font-weight: $global-weight-bold;
            border: none !important;
        }
    }
    .category-questions{
        .accordion-title{
            font-weight: 500;
            span{
                letter-spacing: 0;
            }
            @include breakpoint (large up){
                margin-bottom: rem-calc(5);
            }
        }
        .accordion-content{
            letter-spacing: 0;
            line-height: 1.65;
            a {
                text-decoration: none;
                position: relative;
                &:after {
                    content: '';
                    position: absolute;
                    bottom: rem-calc(-2);
                    left: 0;
                    width: 100%;
                    height: .15385rem;
                    background-color: $black;
                    transform: scaleX(1);
                    transform-origin: bottom left;
                }
            }
        }
        .accordion-item{
            @include breakpoint (large up){
                padding-right: rem-calc(30);
            }
        }
    }
    .grid-container--tezenis{
        @include breakpoint (small down){
            padding: rem-calc(0 3);
        }
    }

    .ca-faq-section {

        min-height: unset;
        margin-top: rem-calc(32);

        .ca-faq-content {
            margin-bottom: rem-calc(30);
            ul {
                margin-bottom: 1.14286rem;
            }
        }

        .ca-faq-title {
            font-family: $body-font-family;
            font-weight: $global-weight-dark;
            font-size: rem-calc(18);
            line-height: rem-calc(27);
        }

        .ca-faq-question {
            font-family: $body-font-family;
            margin: 0;
            padding: rem-calc(25 34 16 0);
            border-bottom: solid #d9d9d9 1px;
            font-size: rem-calc(14);
            letter-spacing: rem-calc(0.2);
            font-weight: 600;
            cursor: pointer;
            position: relative;
            &.active {
                border-bottom: none;
                &:after {
                content: url('../../images/minus-icon.svg') !important;
                }
            }
            &:after {
                content: url('../../images/plus-icon.svg') !important;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 0;
            }
        }
        .ca-faq-answer {
            font-family: $body-font-family;
            font-size: rem-calc(12);
            line-height: rem-calc(18);
            letter-spacing: rem-calc(0.2);
            width: 100%;
            display: inline-block;
            padding: rem-calc (0 0 14 0);
            p {
                margin: 0 0 1.75rem;
            }
            li {
                font-style: normal;
                font-weight: 400;
                font-size: rem-calc(14);
                line-height: rem-calc(21);
                letter-spacing: rem-calc(0.2);
                text-align: left;
            }
        }
        .ca-faq-panel {
            max-height: 0;
            overflow: hidden;
            transition: 0.4s ease-in-out;
            opacity: 0;
            text-align: left;
            &.show {
                opacity: 1;
                border-bottom: 1px solid #d9d9d9;
            }
        }
    }
}
.special-listing-bottom-banner,
.special-top-banner{
    color: white;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    .banner-title{
        margin-bottom: rem-calc(15);
        line-height: rem-calc(20);
        &-top{
            line-height: rem-calc(20);
            margin-bottom: rem-calc(25);
        }
        @include breakpoint(small down){
            margin-bottom: rem-calc(21);
        }
    }
    .links-box{
        .white-link{
            color: white;
            margin: rem-calc(0 10);
            font-weight: 600;
            display: inline-block;
            border: 0;
            span{
                color: white;
                font-size: rem-calc(14);
                border-bottom: 2px solid white;
            }
            @include breakpoint(small down){
                margin-bottom: rem-calc(12);
                &.hide + .white-link,
                &.hidden + .white-link,
                &:last-of-type:not(.hide),
                &:last-of-type:not(.hidden) {
                    display: block;
                }
            }
        }
    }
    img{
        width: 100%;
        height: auto;
    }
    .pos-absolute{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 80%;
        @include breakpoint(small down){
            width: 100%;
            transform: translate(-50%, -48%);
            padding: rem-calc(0) 5%;
        }
    }
}
.special-listing-bottom-banner,
.banner-placement{
    @include breakpoint(medium up){
        width: calc(100% - 0.742vw);
    }
}
.standard-template{
    @include breakpoint(small down){
        &, & + .grid-footer {
            width: calc(100% - 10px) !important;
            margin-right: rem-calc(5) !important;
            margin-left: rem-calc(5) !important;
            .special-listing-bottom-banner{
                width: 100%;
                margin: 0;
            }
        }
    }
}
.filters-and-order{
    @include breakpoint(medium up){
        .refinements--tezenis:not(.fix-position){
            margin-left: rem-calc(-15);
        }
        .sort-order-wrapper--tezenis{
            padding-right: 0;
        }
    }
}
.mb-0{
    @include breakpoint(medium up){
        margin-bottom: 0 !important;
    }
}

.m-video{
    aspect-ratio: 2/3;
            object-fit: cover;
            width: 100%;
}

header {
    transition: transform 0.5s ease;
}

/* Tezenis Restyle */
.search-results{
    .category-product {
        margin-top: rem-calc(19);

        @include breakpoint (medium down) {
            margin-top: rem-calc(18);
        }
    }

    .category-refinements.gender-filters {
        display: block;
        list-style-type: none;
        padding: rem-calc(16 0 8);
        margin: rem-calc(0 0 16);
        white-space: nowrap;
    }

    li.category-refinements__item {
        display: inline-block;
    }

    .search-results__refinements > .gender-filters {
        display: none;
    }

    .search-nav {
        margin-top: rem-calc(16);
        margin-bottom: rem-calc(16);
    }

    &__title {
        margin-bottom: rem-calc(8);
        font-size: rem-calc(32);
        line-height: rem-calc(36);
        font-weight: 600;
        @include breakpoint (medium down) {
          font-size: rem-calc(20);
          line-height: rem-calc(30);
        }
    }

    &__container {
        &--listing-2 {
            .search-results__coremedia-banner {
                &--placement1 {
                    width: calc(100% + 64px);
                    margin: rem-calc(0 0 0 -32); // 40px - 8px (negative margin from grid-margin-x)
                    @include breakpoint (medium down) {
                        width: calc(100% + 24px);
                        margin: rem-calc(0 0 0 -12);
                    }
                }

                &--placement2 {
                    height: 34.33382137628111vw;

                    @include breakpoint (ipad down) {
                        height: auto;
                        margin-bottom: rem-calc(32);
                    }
                }
            }
        }

        &--listing-3 {
            .search-results__coremedia-banner {
                &--placement1,
                &--placement3 {
                    width: calc(100% + 64px);
                    margin: rem-calc(0 0 0 -32); // 40px - 8px (negative margin from grid-margin-x)
                    padding-bottom: rem-calc(32);
                    @include breakpoint (medium down) {
                        width: calc(100% + 24px);
                        margin: rem-calc(0 0 0 -12);
                        padding-bottom: rem-calc(16);
                    }
                }

                .search-results__coremedia-special-card {
                    height: 33.96778916544656vw;
                    @include breakpoint (ipad down) {
                        height: auto;
                        margin-bottom: rem-calc(16);
                    }
                }
            }
        }
    }

    &__product-item {
        @include breakpoint (large up) {
            &--two {
                padding-left: 12vw;

                & + & {
                    padding: 0 12vw 0 0;
                }
            }
        }

        &--big {
            @include breakpoint (tablet up) {
                gap: rem-calc(16);
                .product,
                .second-image {
                    width: 34.48023426061493vw;
                }
            }

            @include breakpoint (medium down) {
                .product {
                    order: 2;
                }

                .product,
                .image-container {
                    width: 100%;
                }
                .second-image {
                    margin-bottom: rem-calc(16);
                }
            }
        }

        &--horizontal {
            .image-container {
                display: flex;
                justify-content: center;

                .tile-image {
                    width: unset;
                }

                &, .tile-image {
                    height: 33.96778916544656vw;
                    @include breakpoint (medium down) {
                        height: 67.64705882352941vw;
                    }
                }
            }
        }

        &--one-variant {
            .product {
                width: 100%;
                @include breakpoint (large up) {
                    display: flex;
                    flex-flow: row wrap;
                    justify-content: center;
                }

                &-tile {
                    @include breakpoint (large up) {
                        width: 34.48023426061493vw;
                    }

                    &__color-variations {
                        @include breakpoint (large up) {
                            height: 51.68374816983895vw;
                            flex-wrap: wrap;
                            max-width: 35vw;
                            overflow: hidden;
                            gap: rem-calc(8);
                            margin-left: rem-calc(16);

                            &--column {
                                &-one {
                                    width: 11.20058565153734vw;
                                }

                                &-two {
                                    width: 22.98682284040996vw;
                                }
                            }
                        }
                        @include breakpoint (ipad down) {
                            flex-direction: row;
                            display: flex;
                            flex-wrap: nowrap;
                            overflow-x: auto;
                            margin: rem-calc(16 -16 0 0);
                            width: calc(100% + 16px);
                            &::-webkit-scrollbar {
                                display: none;
                            }
                        }

                        &-grid {
                            @include breakpoint (large up) {
                                display: grid;
                                row-gap: rem-calc(8);
                            }
                            @include breakpoint (ipad down) {
                                flex-direction: row;
                                flex: 0 0 auto;
                            }
                        }
                    }
                }
            }

            .swatch-container {
                @include breakpoint (large up) {
                    height: calc(17.22791605661298vw - 6px);
                    aspect-ratio: 220/330;
                }
                @include breakpoint (ipad down) {
                    margin-right: rem-calc(8);
                }

                &__image {
                    height: 100%;
                    border-radius: rem-calc(16);
                    transition: opacity 0.2s linear;
                    @include breakpoint (ipad down) {
                        width: 37.06666666666667vw;
                    }

                    &:hover {
                        opacity: 0.7;
                    }
                }
            }
        }
    }
}

.no-results.search-tips {
    margin-top: rem-calc(8);
    .cc-no-results-title{
        padding-top: rem-calc(16);
    }
}

.cc-hidePercentage {
    display: none;
}